import { graphql } from 'gatsby'
import { SERIES_TERM_ID } from '@/constants'
import SEO from '@/components/shared/SEO'
import NewPosts from '@/components/shared/Category/NewPosts'
import RecommendPosts from '@/components/shared/Category/RecommendPosts'
import { Category, Post as IPost } from './types'
import SeriesSlide from '@/components/shared/Series/SeriesSlide'
import { getPodcastsAndPosts } from '@/utils/category'
import Podcasts from './components/Podcasts'
import UCImage from '@/components/shared/UCImage'
import HealthStatusCarousel from '@/components/shared/HealthStatus/HealthStatusCarousel'
import Header from '@/components/shared/Category/Header'

const Topic = (props: any) => {
  const { name, description } = props.pageContext
  const allPosts: IPost[] = props.data.allWpPost.nodes
  const { posts, podcasts } = getPodcastsAndPosts(allPosts)
  const topicCategoryId = props.pageContext.databaseId
  const isEmptyTopic = posts.length + podcasts.length <= 0
  // list series in this topic
  const series: Category[] = []
  allPosts.forEach((post: any) => {
    post.categories.nodes.forEach((category: Category) => {
      if (
        category.parentDatabaseId === SERIES_TERM_ID &&
        series.findIndex(s => s.name === category.name) === -1
      ) {
        series.push(category)
      }
    })
  })

  const graphqlArgs: string = isEmptyTopic
    ? `where: {categoryNotIn: [515, 518]}`
    : `where: {categoryNotIn: [515, 518], categoryIn: [${topicCategoryId}]}`

  return (
    <>
      <SEO title={name} description={description} />
      <div className="flex flex-col overflow-hidden">
        <Header name={name} description={description} isEmpty={isEmptyTopic} />

        {!isEmptyTopic ? (
          <>
            <NewPosts posts={posts} />
            <hr />
            {series.length > 0 && (
              <>
                <div className="flex flex-col base-container">
                  <h1 className="mb-12 section-heading">
                    Bài viết theo series
                  </h1>

                  <SeriesSlide series={series} />
                </div>
                <hr />
              </>
            )}
            <RecommendPosts graphqlArgs={graphqlArgs} />
            {podcasts.length > 0 && <Podcasts podcasts={podcasts} />}
          </>
        ) : (
          <>
            <div className="py-15 desktop:py-30">
              <UCImage />
            </div>
            <div className="base-container md:space-y-12">
              <div className="flex flex-col space-y-4 lg:space-y-8">
                <h1 className="section-heading">Tình trạng sức khỏe</h1>
                <p className="text-caption md:text-base">
                  Hiểu thêm về tình trạng bệnh lý bạn đang gặp phải ngay hôm
                  nay.
                </p>
              </div>
              <HealthStatusCarousel />
            </div>
            <hr />
            <RecommendPosts graphqlArgs={graphqlArgs} />
          </>
        )}
      </div>
    </>
  )
}

export const query = graphql`
  query($id: String) {
    allWpPost(
      filter: {
        categories: {
          nodes: {
            elemMatch: { id: { eq: $id }, parentDatabaseId: { ne: 515 } }
          }
        }
      }
      limit: 3
    ) {
      nodes {
        title
        slug
        excerpt
        modified(formatString: "DD-MM-YYYY")
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        categories {
          nodes {
            name
            slug
            parentDatabaseId
          }
        }
        vhl_fields {
          podcastSpotifyLink
          podcastYoutubeLink
        }
      }
    }
  }
`

export default Topic
